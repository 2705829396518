import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import partner1 from '../assets/img/partner1.png';
import partner2 from '../assets/img/partner2.png';
import partner3 from '../assets/img/partner3.png';
import partner4 from '../assets/img/partner4.png';
import partner5 from '../assets/img/partner5.png';
import partner6 from '../assets/img/partner6.png';


class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
    this.images = [partner1, partner2, partner3, partner4, partner5, partner6];
  }

  componentDidMount() {
    this.interval = setInterval(this.nextImage, 2000); // Change image every 2 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextImage = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex + 1) % this.images.length,
    }));
  };

  goToImage = (index) => {
    this.setState({ currentIndex: index });
  };

  render() {
    const { currentIndex } = this.state;
    const imagesToShow = [...this.images, ...this.images]; 
    const dotIndex = Math.floor(currentIndex / 2) % 3;

    return (
      <div className="slider wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <Container className='carousel mt-5 p-3'>
            
          <div className="image-slider">
            <div
              className="image-slider-inner"
              style={{
                transform: `translateX(-${(currentIndex * 100) / 6}%)`,
              }}
            >
              {imagesToShow.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Partner ${index + 1}`}
                  className="item img-fluid"
                />
              ))}
            </div>
          </div>
          <div className="dots-container">
            {[0, 1, 2].map((index) => (
              <div
                key={index}
                className={`dot ${dotIndex === index ? 'active' : ''}`}
                onClick={() => this.goToImage(index * 2)}
              />
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

export default Partner;
