import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class DonationForm extends Component {
  constructor(props){
    super(props);
    this.state={
        ColumnClassName:''
    }
  }
  getPageLoadOnLeftPanel=()=>{
     this.setState({ColumnClassName:  this.props.location.pathname == '/Athirudram' ? 'col-12 col-lg-12' :'col-6 col-lg-6'})
   
  }
  componentDidMount(){
    this.getPageLoadOnLeftPanel()
  }
  render() {
   

    return (
        <>
         <form className={ this.props.location.pathname == '/Athirudram' ? 'row border' : 'row'}>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="name" class="form-label">Name:</label>
          <input type="text" class="form-control" id="name" name="name" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="age" class="form-label">Age:</label>
          <input type="number" class="form-control" id="age" name="age" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="address" class="form-label">Address:</label>
          <input type="text" class="form-control" id="address" name="address" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="pincode" class="form-label">Pincode:</label>
          <input type="text" class="form-control" id="pincode" name="pincode" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="phone" class="form-label">Ph:</label>
          <input type="tel" class="form-control" id="phone" name="phone" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="email" class="form-label">Email:</label>
          <input type="email" class="form-control" id="email" name="email" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="pan" class="form-label">Pan No.:</label>
          <input type="text" class="form-control" id="pan" name="pan" required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="pan" class="form-label">Transtration No.:</label>
          <input type="text" class="form-control" id="pan" name="trx" required/>
        </div>

        <div class="d-flex justify-content-center mt-2 mb-2">
           <button type="submit" class="btn btn-success me-2">Submit</button>
        </div>

      </form>
        </>
    );
  }
}

export default withRouter(DonationForm)
