import React, { Component } from 'react';

class DonationAccountInfo extends Component {
  constructor(props){
    super(props);
    this.state={}
  }
  componentDidMount(){
   
  }
  render() {
   

    return (
        <>
        {this.props.leftSectionAccount.length>0 && this.props.leftSectionAccount.map((item,index)=>{
 return(
        <>
        <p className='mb-0'>
          <b>Account Name:</b> {item.accontName},<br/>
          <b>Bank:</b> {item.bank}<br/>
          <b>Branch:</b> {item.branch}<br/>
          <b>Account Type: {item.accontType}</b><br/>
          <b>Account Number: <span className='fw-1 fs-4' style={{wordBreak:"break-word"}}>{item.accountNumber}</span></b>
         </p>      
        <p className='fw-1 fs-5 mb-0'>IFSC CODE: {item.ifscCode}</p>
        <p className='fw-1 fs-5'>UPI ID: {item.upiId}</p>        
        <h5>Donations</h5>
        <img src={item.qurCode} className='flex-shrink-0 me-3 mb-3 img-fluid' style={{width:"180px"}}/>
        </> )
      })}
      <p><small>Kindly fill in your details below your contribution details and submit</small></p>
        </>
    );
  }
}

export default DonationAccountInfo;
