import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import Banner from '../assets/img/Sri Rama-1 1@3x.png'
import Banner1 from '../assets/img/namaskaram 1.png'
import Video from '../assets/img/samplevideo.mp4'
import Layer from '../assets/img/Layer.png'
import Layer1 from '../assets/img/Group.png'
import Layer2 from '../assets/img/Layer_2.png'
import Layer3 from '../assets/img/Layer_3.png'
import homefooter from '../assets/img/homefooterbanner.png'
import homefootertext from '../assets/img/homefootertext.png'


export default class HomeContent extends React.Component {
  constructor() {
    super();
    this.state = {homeContent:{}};
   
  }
  // Fetching data from JSON when the component mounts
  componentDidMount() {
    debugger;
    fetch('https://sharavisual.com/data/home.json')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.setState({homeContent:data})
      console.log('Fetched data:', data);
      // You can now use the data in your component state
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    }); 
  }     
  render() {
    return (
      <>
<div class="container-fluid page-header p-0 mb-5 wow fadeIn" data-wow-delay="0.1s" style={{ backgroundColor: "#DB7527" }}>
      <div class="container text-center py-5" >
          <div className='row'>
             <div className='col-lg-7'>
                <img src={Banner} className='w-100 p-3' />
             </div>
             <div className='col-lg-5'>
              <img src={Banner1} className="d-block mx-auto w-50 " alt="Banner" />
              <h2 className='content fw-light text-center'>Namskaram</h2>
              <h4 className='content fs-2 fw-light text-center text-yellow'>Welcome to all devotees</h4>
              <hr></hr>
             </div>
          </div>
        
      </div>
      <h4 className='text-center fs-5 p-2' style={{ color: "#DB7527",backgroundColor: "#FFDB00" }}><marquee behavior="scroll" direction="left" scrollamount="5">Hare Rama Hare Rama, Rama Rama Hare Hare !! Hare Krishna Hare Krishna, Krishna Krishna Hare Hare !!</marquee>
      </h4>
</div>

<div class="container-xxl gy-5 py-3">
      <div class="container">
        <div class="row gy-5 mb-5">
          <div class="col-lg-6 p-2 wow fadeInUp" data-wow-delay="0.1s" style={{ backgroundColor: "#DB7527" }}>
            <video width="100%" height="auto" autoPlay="on" className='p-3 text-center' >
                <source src={"https://sharavisual.com/assets/home/video.mp4"} type="video/mp4" />
              </video>
          </div>
          <div class="col-lg-6  background wow fadeInUp mx-auto customMargin homeContent" data-wow-delay="0.3s">
          <h6 class="text-uppercase mb-2" >{this.state.homeContent!=undefined && this.state.homeContent.homeTitle}</h6>
            <p class="mb-0">
            {this.state.homeContent!=undefined && this.state.homeContent.description}
            </p>
          </div>
        </div>
        </div>
  </div>

  <div class="container" data-aos="fade-up">
  <div class="row gy-4">
    
{
    this.state.homeContent.section!=undefined && this.state.homeContent.section.length >0 && 
       this.state.homeContent.section.map((item,index)=>{
        debugger;
      return(
          <>
          
            <div class="col-lg-6 p-5" style={{backgroundColor:item.backgroundColor}}>
            <div class="card-item">
            <a href={item.link} className='text-decoration-none'> 
              <div class="row">
                <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                
                  <div class="card-bg p-2">
                    <img src={item.img} style={{width:"120px", objectFit:"contain"}}/>
                  </div>
                </div>
                <div class="col-7 col-sm-7 col-md-7 col-lg-7 d-flex align-items-center">
                  <div class="card-body">
                    <h4 class="card-title">{item.name}</h4>                    
                  </div>
                </div>
              </div>
              </a>
            </div>
          </div>
          
          </>
      )
})}
    </div>

      </div>


    <div class="container-fluid home-footer py-2 mt-5" style={{background: `url(${homefooter})`}}>
      <div class="container py-5 text-center">      
        <img class="animated slideInDown" src={homefootertext} style={{width:"400px"}}/>
      </div>           
    </div>
      </>
    );
  }
}