import React from 'react';
import { Container } from 'react-bootstrap';
import $ from "jquery";
import WOW from 'wowjs';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';


import Home from '../pages/home';
import Header from '../component/header';
import Footer from '../component/footer';
import MenuSection from '../component/navbar';
import About from '../pages/about';
import GetInvolved from '../pages/getInvolved';
import Athirudram from '../pages/athirudram';
import AthirudramDetails from '../pages/athirudramDetails';
import ActivitieSection from '../pages/activities';
import Sponsor from '../pages/sponsor';
import Privilage from '../component/privilage';
import Rsponsors from '../pages/Regularsponsor';
import Trustees from '../pages/trustees';
import Admin from '../pages/admin/login';


export default class Layout extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  componentDidUpdate() {
    // Reinitialize WOW.js in case new elements are added dynamically
    this.wow.sync();
  }
  componentDidMount(){
    this.OnLoadScrollAnimateCss();
  }

 OnLoadScrollAnimateCss=()=>{
 // Initialize WOW.js after component is mounted to the DOM
 this.wow = new WOW.WOW({
    live: false, // disable automatic update on dynamic content
  });
     this.wow.init();
    // Sticky Navbar
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $('.sticky-top').addClass('shadow-sm').css('top', '0px');
      } else {
        $('.sticky-top').removeClass('shadow-sm').css('top', '-100px');
      }
    });     

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });
      $('.back-to-top').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
      return false;
    });
  }

  render() {
    return (
         <Container fluid className='p-0'>
           <Header/>
           <MenuSection {...this.props}/>
           <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/AboutUs" component={About} />
          <Route path="/GetInvolved" component={GetInvolved} />
          <Route path="/Athirudram" component={Athirudram} />
          <Route path="/AthirudramDetails" component={AthirudramDetails} />          
          <Route path="/Activities" component={ActivitieSection} />
          <Route path="/Sponsor" component={Sponsor} />
          <Route path="/Underprivilege" component={Privilage} />
          <Route path="/RegularSponsor" component={Rsponsors} />
          <Route path="/Trustees" component={Trustees} />
          <Route path="/admin" component={Admin} />
          </Switch>
         
           <Footer/>
         </Container>
    );
  }
}