import React from 'react';
import leftBannerOne from '../assets/img/about-left-banner-1.png';
import leftBannerTwo from '../assets/img/about-left-banner-2.png';
import leftBannerThree from '../assets/img/about-left-banner-3.png';
export default class About extends React.Component {
  constructor() {
    super();
    this.state = {
      leftSection:[],
      aboutUs:[]
    };
  }
// Fetching data from JSON when the component mounts
componentDidMount() {
  debugger;
  fetch('https://sharavisual.com/data/aboutUs.json')
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    this.setState({aboutUs:data.aboutUs,
                   leftSection:data.leftSection
                  })
    console.log('Fetched data:', data);
    // You can now use the data in your component state
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  }); 
}
  render() {
    return (
       <>
         <div class="container" data-aos="fade-up">

<div class="row justify-content-around gy-4">
  <div class="col-lg-10 d-flex flex-column justify-content-center">
    <div className='row'>
      <div className='col-3 col-lg-3'>
        {this.state.leftSection.length>0 && this.state.leftSection.map((item,index)=>{
          return(
            <img src={item.img} className='flex-shrink-0 me-3 mb-3 img-fluid' style={{width:"100%"}}/>
          )
        })}
      
      </div>
      <div className='col-9 col-lg-9 aboutContent'>
      {this.state.aboutUs.length>0 && this.state.aboutUs.map((item,index)=>{
          return(
            <div className='col-lg-12'>
            <h4><a href={item.link} class="text-decoration-none">{item.title}</a></h4>
            {item.subTitle!="" && <h6>{item.subTitle}</h6> }
            <p dangerouslySetInnerHTML={{__html: item.description}}></p>
           </div>
          )
            })}       
      </div>
      
    </div>
  </div>
</div>
</div>
       </>
    );
  }
}