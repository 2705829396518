import React from 'react';
import Regularsponsors from '../component/regularsponsor';
import Partner from '../component/partner';
import Trust from '../component/Trusties';
import Gallery from '../component/Gallery';
export default class Sponsor extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return (
         <>
         <Regularsponsors/>
         <Partner/>
         <Gallery/>
         <Trust/>
         </>
    );
  }
}
