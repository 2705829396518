import React from 'react';
import Rsponsors from '../assets/img/Rsponsors.png';

export default class Regularsponsors extends React.Component {
    constructor() {
        super();
        this.state = { color: "red" };
    }
    render() {
        return (
            <>
            <div className='container-fluid p-3'>
                <div className='row mt-3'>
                <h2 style={{color:"#DB7527"}}>Regular Sponsors</h2>
                    <div className='col-lg-12'>
                        <img className='img-fluid' src={Rsponsors}></img>
                    </div>
                </div>
            </div>
            </>
        );
    }
}