import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
class Admin extends React.Component {
  constructor() {
    super();
    this.state = {login:""};
  }
  render() {
    return (
         <>
    <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <h4>Admin</h4>
              <h6 class="fw-light">Sign in to continue.</h6>
              <form class="pt-3">
                <div class="form-group">
                  <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username"></input>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password"></input>
                </div>
                <div class="mt-3">
                  <a class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="#" onClick={this.login}>SIGN IN</a>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>     
    </div>    
  </div>
         </>
    );
  }
}
export default withRouter(Admin);