import React, { Component } from 'react';
import Group from "../assets/img/Group 8.png"

class Privilage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [], // To store the uploaded images
        };
    }
    componentDidMount() {
        // Dynamically require all images from the public/images folder
        const images = this.importAll(require.context('../../public/images', false, /\.(jpg|jpeg|png|gif)$/));

        this.setState({ images });
    }

    importAll(r) {
        let images = [];
        r.keys().map((item, index) => { images.push(r(item)); });
        return images;
    }

    render() {
        const profileDetails = [
            {
                title: "Education",
                content: "Underprivileged communities often face barriers to quality education, such as inadequate school funding, lack of access to experienced teachers, and limited resources. This can lead to lower educational attainment and fewer opportunities for upward mobility. Programs like scholarships, tutoring, and mentorship can help bridge these gaps."
            },
            {
                title: "Healthcare",
                content: "Access to healthcare is often limited for underprivileged populations. They may encounter barriers such as lack of insurance, transportation issues, or fewer healthcare facilities in their communities. Initiatives like community health clinics and mobile health services can improve access and address specific health disparities."
            },
            {
                title: "Senior Citizens",
                content: "Many senior citizens from underprivileged backgrounds struggle with isolation, inadequate healthcare, and financial insecurity. Support services, including meal programs, transportation assistance, and social engagement activities, can significantly enhance their quality of life."
            },
            {
                title: "Social Support",
                content: "Social support systems are crucial for underprivileged individuals and families. This includes access to food assistance programs, housing support, mental health services, and community organizations that offer resources and advocacy. Strong support networks can empower individuals to improve their circumstances."
            }
        ];
        const { images } = this.state;

        return (
            <>
                <div className="container">
                    <div className="row py-5">
                        <h2 style={{ color: "#DB7527" }}>Underprivilege</h2>
                        <div className="col-lg-12 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                            <p>
                                Underprivileged" refers to individuals or groups who have fewer advantages or resources compared to others in society. This can encompass various aspects, such as limited access to quality education, healthcare, housing, and employment opportunities. Underprivileged communities often face systemic challenges that can perpetuate cycles of poverty and inequality.
                            </p>

                        </div>
                        {profileDetails.map((detail, index) => (
                            <div key={index} className="col-lg-12 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="">
                                    <h5><strong>{detail.title}</strong></h5>
                                    <p>{detail.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='container'>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-12 mb-3 wow fadeInUp" data-wow-delay="0.1s">
                        <img className="img-fluid w-100 w-sm-50 w-md-50" src={Group} alt="Group" />
                    </div>
                </div>
                <div className="container py-4">
                    <h2 style={{ color: "#DB7527" }}>Image Gallery</h2>
                    <div className="row py-4 wow fadeInUp">
                        {images.map((image, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                <div className="card" >
                                    <img
                                        src={image}  // Dynamically loaded image
                                        alt={`Uploaded ${index}`}
                                        className="card-img-top"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default Privilage;
