import { data } from 'jquery';
import React, { Component } from 'react';

class Activities extends Component {
    constructor(props) {
        super(props);
        this.state={
            currentYear:new Date().getFullYear()
        }
      }//End Constructor
    getDateDay =(date)=>{
        let inputDate = new Date(date);

// Options for formatting the date
let options = { day: '2-digit', month: 'short' };

// Format the date
let formattedDate = inputDate.toLocaleDateString('en-US', options);

// Get the day of the week
let dayOfWeek = inputDate.toLocaleDateString('en-US', { weekday: 'long' });

// Combine the formatted date and day of the week
 
let output = `${formattedDate}, ${dayOfWeek}`;

return output
    }


  render() {
    const ActivitieDetails = [
      {
        eventName: "Anjaneyotsavam @ Jain Bhavan, Ulsoor (Near Car Street)",
        date: "2024-08-11",
        time: "4:00PM",
        year:"2024"
      },
      {
        eventName: "Anjaneyotsavam @ Jain Bhavan, Ulsoor (Near Car Street)",
        date: "2024-08-21",
        time: "8:00PM",
        year:"2024"
      },
      {
        eventName: "Anjaneyotsavam @ Jain Bhavan, Ulsoor (Near Car Street)",
        date: "2024-10-25",
        time: "9:00PM",
        year:"2024"
      }
      
    ];

    

    return (
      <div className="container">
        <div className="row py-5">
            <h2 style={{color:"#DB7527"}}>Activities {this.state.currentYear}</h2>
          {ActivitieDetails.map((detail, index) => {
               
                return(
                   detail.year == this.state.currentYear ?  <>
                   <div key={index} className="col-lg-10 border border-top-0 border-start-0 border-end-0 wow fadeInUp " data-wow-delay="0.1s">
                                           
                        <p className='mb-2'><span className='m-0 me-3'>{this.getDateDay(detail.date)} </span><span className='m-3'>{detail.time}</span> <span className='m-3'>{detail.eventName}</span></p>
                      
                    </div>
                    </> : "--No Activities-- "
                  )
               
            })}
        </div>
      </div>
    );
  }
}

export default Activities;
