import React, { Component } from 'react';
import axios from "axios";

class InvolvedFrom extends Component {
    constructor() {
        super();
        this.state = {
            sectionName:'',
            VolunteerCategory:'',
            name:'',
            address1: '',
            address2:'',
            city:'',
            state: '',
            zipcode: '',
            PANno:'',
            amount:"",
            message: '',
            Option_1:[{
                value:"Volunteer", label:"Volunteer"},
               {value:"ProvideFinancialSupport", label:"Provide Financial Support"},
                {value:"ContributeToAnnadanan", label:"Contribute to Annadanan"}
           
           ],
            Option_2:[{
                value:"VedaSamrakshanan", label:"Veda Samrakshanan"},               
                {value:"GouDhanam", label:"GouDhanam"},
                {value:"DashaDhanam", label:"DashaDhanam"},
                {value:"Culturalprograms", label:"Culturalprograms"},
            
            ],
            Option_3:[
                {value:"Annadhanam", label:"Annadhanam"}
                
            
            ],
            SelectedOption_1:"",
            SelectedOption_2:""
        };
            
};
      
        // Handle input change
    handleInputChange = (e) => {
        debugger;
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };  
    handleSubmit = (e) => {
        e.preventDefault();
        const { sectionName, VolunteerCategory, name, address1, address2, city, state, zipcode, PANno, amount } = this.state;
    
        // Create a FormData object to hold the data as form-data (multipart/form-data)
        const formData = new FormData();
        formData.append('sectionName', sectionName);
        formData.append('VolunteerCategory', VolunteerCategory);
        formData.append('name', name);
        formData.append('address1', address1);
        formData.append('address2', address2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zipcode', zipcode);
        formData.append('PANno', PANno);
        formData.append('amount', amount);
    
        axios.post('https://sharavisual.com/api/involved.php', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
            console.log(response.data);
            debugger;
            if (response.status) {
                alert('New Contact Successfully Added.');
            } else {
                alert('Failed to add contact: ' + response.message);
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    };
    
            

    componentDidMount() {
      
      }   
    render() {
        return (

           <>
           <h2>GetInvolved</h2>
                                <hr className='normal'></hr>
                                <form class="forms-sample mt-4">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                        <label for="option" class="form-label">I would like to:</label>
                                        <select class="form-select" name="sectionName" id="option" required  onChange={this.handleInputChange}>
                                              {this.state.Option_1.map((item,index)=>{
                                                return(
                                                        <option value={item.value}>{item.label}</option>
                                                )
                                              })}
                                            
                                           
                                        </select>
                                        </div>
                                       {this.state.sectionName !="" && (this.state.sectionName =="ProvideFinancialSupport") && <div className='col-lg-6 mb-3'>
                                        <label for="VolunteerCategory" class="form-label">Be a volunteer:</label>
                                        <select class="form-select" name="VolunteerCategory" id="VolunteerCategory" required  onChange={this.handleInputChange}>
                                        {this.state.Option_2.map((item,index)=>{
                                                return(
                                                        <option value={item.value}>{item.label}</option>
                                                )
                                              })}
                                        </select>
                                        </div>}
                                        {this.state.sectionName !="" && (this.state.sectionName=="ContributeToAnnadanan") && <div className='col-lg-6 mb-3'>
                                        <label for="VolunteerCategory" class="form-label">Be a volunteer:</label>
                                        <select class="form-select" name="VolunteerCategory" id="VolunteerCategory" required  onChange={this.handleInputChange}>
                                        {this.state.Option_3.map((item,index)=>{
                                                return(
                                                        <option value={item.value}>{item.label}</option>
                                                )
                                              })}
                                        </select>
                                        </div>}
                                    </div>

                                    <div className='col-lg-6 mb-3'>
                                        <label for="name" class="form-label">Name</label>
                                        <input type="text" class="form-control" id="name" name="name" required  onChange={this.handleInputChange} />
                                    </div>

                                    <div class="mb-3">
                                        <label for="addressLine1" class="form-label">Street Address Line 1</label>
                                        <input type="text" class="form-control" id="addressLine1" name="address1" required  onChange={this.handleInputChange}/>
                                    </div>

                                    <div class="mb-3">
                                        <label for="addressLine2" class="form-label">Street Address Line 2</label>
                                        <input type="text" class="form-control" id="addressLine2" name="address2"  onChange={this.handleInputChange}/>
                                    </div>

                                    <div class="row g-3">
                                        <div class="col-md-4">
                                            <label for="city" class="form-label">City</label>
                                            <input type="text" class="form-control" id="city" name="city" required  onChange={this.handleInputChange}/>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="state" class="form-label">State / Province</label>
                                            <input type="text" class="form-control" id="state" name="state" required  onChange={this.handleInputChange}/>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="zipcode" class="form-label">Postal / Zip Code</label>
                                            <input type="text" class="form-control" id="zipcode" name="zipcode" required  onChange={this.handleInputChange}/>
                                        </div>

                                       {(this.state.sectionName == "ProvideFinancialSupport"||this.state.sectionName=="ContributeToAnnadanan") && <div class="col-md-4">
                                            <label for="PANno" class="form-label">PAN No</label>
                                            <input type="text" class="form-control" id="PANno" name="PANno" required  onChange={this.handleInputChange}/>
                                        </div>}

                                        {(this.state.sectionName == "ProvideFinancialSupport"||this.state.sectionName=="ContributeToAnnadanan")  &&  <div class="col-md-4">
                                            <label for="amount" class="form-label">Amount</label>
                                            <input type="text" class="form-control" id="amount" name="amount" required  onChange={this.handleInputChange}/>
                                        </div>}

                                    </div>
                                    <div class="d-flex justify-content-center mt-4">
                                        <button type="submit" class="btn btn-success me-2" onClick={this.handleSubmit}>Submit</button>
                                    </div>
                                </form>
           </>



        );
    }
}

export default InvolvedFrom;
